<template>
  <Breadcrumb :urls="urls" v-if="comUnmount"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
  <WhatsAppButton></WhatsAppButton>
  <div class="ps-page--shop" v-if="translationloaded==true">
    <div class="ps-container">
      
      <template v-if="search_string!=''&& search_string!=null">
         <div class="search_result mt-2">{{translate('showingsearchresultsfor')}} {{search_string}}</div>
      </template>
     
      <div class="ps-layout--shop-new">
        
        <a class="FilterMainBtn" id="menuIcon1" onclick="openNav2()">{{translate('filter')}}</a>

        <div class="layout__right_new mr_top25">
          <div class="ps-shopping ps-tab-root">
            <div class="ps-shopping__header">
              <p>
                <strong> {{ productCnt }} &nbsp;</strong>{{translate('productsFound')}}
              </p>
          
            </div>
            <div class="ps-tabs">
              <div class="ps-tab active" id="tab-1">
                <div class="ps-prduct_box">
                  <div class="rowMainAll">
                    <div
                      class="produtDeatilsDivFloat"
                      v-for="product in getRecentProducts"
                      :key="product.productId"
                    >
                      <ProductCard
                        :product="product"
                        :web_admin_url="web_admin_url"
                        @addToCart="addtocart($event)"
                        :cart_ajax="cart_ajax"
                        :cart_product_url_key="cart_product_url_key"
                        :currency="currency"
                         @updateCartQty="updateCart($event)" 
                        :translationloaded="translationloaded"
                        :translate="translate"
                      >
                      </ProductCard>
                    </div>
                  </div>

                  <EmptyItem
                    v-if="
                      productList &&
                        productList.length < 1 &&
                        page_ajax == 'closed'
                    "
                  ></EmptyItem>
                </div>

                <div class="w-100" >
                  <ButtonLoader
                    :loaderColor="loaderColor"
                    :loaderStyle="loaderStyle"
                    :cart_ajax="page_ajax"
                  >
                  </ButtonLoader>
                  
                </div>
                <template v-if="productList.length >4">
                   <Observer @intersect="intersect"></Observer>
                </template>
                 <template v-if="productList.length >4">
                    <span class="scrollTop" @click="topToBottom">
                          <i class="fa fa-angle-up" aria-hidden="true"></i>
                    </span>
                 </template>
              
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" id="shop-filter-lastest" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="list-group">
                <a class="list-group-item list-group-item-action" href="#"
                  >Sort by</a
                ><a class="list-group-item list-group-item-action" href="#"
                  >Sort by average rating</a
                ><a class="list-group-item list-group-item-action" href="#"
                  >Sort by latest</a
                ><a class="list-group-item list-group-item-action" href="#"
                  >Sort by price: low to high</a
                ><a class="list-group-item list-group-item-action" href="#"
                  >Sort by price: high to low</a
                ><a
                  class="list-group-item list-group-item-action text-center"
                  href="#"
                  data-dismiss="modal"
                  ><strong>Close</strong></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>
<script>
import Config from "@/config";
import axios from "axios";
import ProductCard from "@/components/ProductCard";
import ButtonLoader from "@/components/ButtonLoader";
import EmptyItem from "@/components/EmptyItem";
import Breadcrumb from "@/components/Breadcrumb";
import WhatsAppButton from "@/components/WhatsAppButton";
import Observer from "@/components/Observer";
import { mapGetters,mapActions } from "vuex";
import helper from "@/helper/helper";

export default {
  name: "RecentlyViewedItems",
  components: {
    ProductCard,
    ButtonLoader,
    EmptyItem,
    Breadcrumb,   
    Observer,
    WhatsAppButton
  },

  props: ["url_change", "changed_url",'translationloaded',
      'translate'],
  watch: {
    // changed_url(newUrl) {
    //   this.url_key = newUrl;
    //   this.productList = [];
    //   this.currentpage = 1;
    //   this.getProductFilters();
    // },
    search_string(text) {
     
      this.productList = [];
      this.currentpage = 1;     
      this.search_status = text;
     
    },
    // sort_order() {
    //   this.productList = [];
    //   this.currentpage = 1;
    //   this.getProductsList();
    // },
    productFilterValues() {
      this.productList = [];
      this.currentpage = 1;     
    },
    $route(value) {        
        this.url_key = value.params.category;
        this.search_string = value.query.search??'';
        this.productList = [];
        this.currentpage = 1;      
        helper.backtoTop();
        this.getCategoryName();
       
    },
    
    
  },
  data() {
    return {
      urls: [
        {
          path: "/",
          name: "Home",
        },
        {
          path: "/products",
          name: "Products",
        },

        {
          path: "/recently-viewed-items",
          name: "Recently viewed items",
        },
      ],
      productList: [],
      web_admin_url: "",
      url_key: "",
      url_update: "",
      guest_id: "",
      cust_id: null,
      pincode: "",
      customerCart: [],
      vendor_url_key: "",
      cart_ajax: "closed",
      page_ajax: "",
      cart_product_url_key: "",
      loaderColor: "red",
      loaderStyle: {
        width: "40px",
      },
      currency: "",
      priceRange: [],
      filterPriceRange: [],
      productFilterData: [],
      selectedBrands: [],
      productFilterValues: "",
      sort_order: "latest",
      initial_loading_status: false,
      currentpage: 1,
      search_status:'',
      current_page_url:this.$route.params.category,
      comUnmount:true,
      catList:this.$store.state.category_list,
      search_string:this.$route.query.search,
      defaultMaxPrice:500000000,
      defaultMinPrice:0
    };
  },
  mounted() {
    this.storeRecentViewedProducts();
    let $this = this;
    let parameters = this.$route.params;
    $this.url_key = parameters.category;
    $this.guest_id = localStorage.getItem("guestId");
    $this.cust_id = localStorage.getItem("custId");
    $this.vendor_url_key = Config.VENDOR_URL_KEY;
    $this.web_admin_url = Config.WEB_ADMIN_URL;
    $this.currency = Config.CURRECNCY;
    //  $this.searchString =$this.$route.query.search;
    if($this.$route.query.brand!=''){
      $this.selectedBrands.push($this.$route.query.brand);
       this.productFilterValues = this.selectedBrands.toString();
    }   
    helper.backtoTop();
     
    
  
  },
  created() {
    this.getCategoryName();
  },
  methods: {
    ...mapActions(['storeCategoryList','storeCartSummary',"storeRecentViewedProducts"]),
    topToBottom(){
       helper.backtoTop();
    },

    updateCart(cartItemInfo){
     
       if(cartItemInfo.product_qty==0){
          this.rmvCartItem(cartItemInfo);
       }
       
       if(cartItemInfo.product_qty!=0){

        let guestId=null;
        let custId =null;
        custId=localStorage.getItem("custId");
        if(custId==null){
            guestId =localStorage.getItem("guestId");
        }

                let  $this =this;  
                let cartUpdateUrl ='';
                if(cartItemInfo.type =='add' ){
                    cartUpdateUrl =`${Config.BASE_URL}Order/CartItemAddQtyByUrlKey`;
                }else if(cartItemInfo.type =='sub'){       
                    cartUpdateUrl =`${Config.BASE_URL}Order/CartItemSubQtyByUrlKey`;
                }
                if(cartUpdateUrl!=''){
                     axios.post(`${cartUpdateUrl}`,{
                       "urlKey":cartItemInfo.url_key,
                        "guestId": guestId,
                        "custId":custId
                     },Config.ADMIN_API_HEADER).then(function(response){
                        if(response.status==200){
                            
                             

                               let payload ={
                                'cusId':custId,
                                'guestId':guestId,
                                'vendorUrlkey': Config.VENDOR_URL_KEY         
                                };
                                $this.$store.dispatch("getCart",payload).then(function(){                                   
                                    $this.$emit('cartUpdated',$this.cart_info);
                                    $this.storeCartSummary();
                                  
                                });
                            
                        }
                    }).catch(function(error){                       
                        $this.$toast.error(`${error.response.data.Message}`);
                         $this.qtyReset =1;
                    });
                }

       }
  
    },
    rmvCartItem(cartItemInfo){
       let $this = this;
       $this.cart_ajax = 'open';
       let guestId=null;
        let custId =null;
        custId=localStorage.getItem("custId");
        if(custId==null){
            guestId =localStorage.getItem("guestId");
        }

    
         axios.post(`${Config.BASE_URL}Order/RemoveCartItemByUrlkey`,{
                       "urlKey":cartItemInfo.url_key,
                        "guestId": guestId,
                        "custId":custId
                     },Config.ADMIN_API_HEADER).then(function(response){
                        if(response.status==200){
                             $this.cart_ajax = 'closed';                             

                               let payload ={
                                'cusId':custId,
                                'guestId':guestId,
                                'vendorUrlkey': Config.VENDOR_URL_KEY         
                                };
                                $this.$store.dispatch("getCart",payload).then(function(){                                   
                                    $this.$emit('cartUpdated',$this.cartItemInfo);
                                   // $this.storeCartSummary();
                                  
                                });
                            
                        }
                    }).catch(function(error){                       
                        $this.$toast.error(`${error.response.data.Message}`);
                         $this.qtyReset =1;
                    });
    
  },
    getCategoryName(){     
       let category='';
       let $this =this;
        if(this.getCategoryList!='' && $this.url_key!=''){
             category =  this.getCategoryList.filter(function(item) {
                    if (item.catUrlKey == $this.url_key) {
                        return true;
                    }
                    return false;
                });
        }else{
          $this.storeCategoryList().then(function(){
            $this.getCategoryName();
          });
        }
        if(category!=''&& this.getCategoryList!=''){
          $this.urls=[];
          $this.current_catName=category[0].catName;
           let parts =category[0].code.split("#");
           
           parts.forEach(function(catId){

               $this.getCategoryList.filter(function(item) {
                    if (item.catId == catId) {
                         $this.urls.push({'path':`/products/${item.catUrlKey}`,'name':item.catName});
                      
                    }
                    return false;
                });

           });

           $this.urls[0]={'path':`/`,'name':'Home'};         
          $this.comUnmount = false;
          $this.$nextTick(() => {              
                $this.comUnmount = true;
          });
        }
      
    },
    goToDetailpage(urlkey) {
      this.$router.push({ path: `/product-detail/${urlkey}` });
    },
    discountPercentage(product) {
      if (product.unitPrice > product.specialPrice) {
        let difference = product.unitPrice - product.specialPrice;

        let percentage = (difference * 100) / product.unitPrice;

        return percentage.toFixed(2);
      } else {
        return 0;
      }
    },

    addtocart(productInfo) {
      let urlKey = productInfo.url_key;
      let productQty = productInfo.product_qty;
      let $this = this;
      $this.cart_ajax = "open";
      $this.cart_product_url_key = productInfo.url_key;
      let payload = {
        cusId: $this.cust_id != null ? $this.cust_id : "",
        guestId: $this.cust_id == null ? $this.guest_id : "",
        pincode: $this.pincode,
        urlKey: urlKey,
        productQty: productQty,
        vendorUrlkey: $this.vendor_url_key,
      };

      $this.$store
        .dispatch("addToCart", { payload: payload, toast: $this.$toast })
        .then(function() {
          // console.log(response);
          $this.cart_ajax = "closed";
          $this.cart_product_url_key = "";
          $this.customerCart = $this.$store.state.customer_cart;
          $this.$emit("cartUpdated", $this.customerCart);
        });
    },
    setPricerange(price) {
      this.priceRange = price;
      if (price[1] != undefined) {
        this.defaultMaxPrice = this.priceRange[1];
        this.defaultMinPrice = this.priceRange[0]
        this.productList = [];
        this.currentpage = 1;
        // this.getProductsList();
      }
    },
    getProductFilters() {
      let $this = this;
      $this.page_ajax = "open";
      axios
        .post(
          `${Config.BASE_URL}Product/SearchFilter`,
          {
            currentpage: $this.currentpage,
            custId: $this.cust_id,
            filter: { category: $this.url_key },
            filtervalues: "",
            guestId: $this.cust_id == null ? $this.guest_id : "",
            maxPrice: $this.priceRange[1],
            minPrice: $this.priceRange[0],
            pagesize: 100,
            vendorUrlKey: $this.vendor_url_key,
            searchstring: $this.$route.query.search,
            sortorder: { direction: "asc", field: "" },
          },
          Config.ADMIN_API_HEADER
        )
        .then(function(response) {
          if (response.status == "200") {
            if($this.$route.query.search!=''){
                $this.defaultMaxPrice=response.data.Data.maxPrize?? $this.defaultMaxPrice;
                $this.defaultMinPrice =response.data.Data.minPrize??$this.defaultMinPrice;
            }
            $this.filterPriceRange = [
              response.data.Data.minPrize != ""
                ? response.data.Data.minPrize
                : 0,
              response.data.Data.maxPrize != ""
                ? response.data.Data.maxPrize
                : 500,
            ];
            $this.priceRange = [
              response.data.Data.minPrize != ""
                ? response.data.Data.minPrize
                : 0,
              response.data.Data.maxPrize != ""
                ? response.data.Data.maxPrize
                : 500,
            ];

            $this.productFilterData = response.data.Data;
            $this.currentpage = 1;
            // $this.getProductsList();
          }
        });
    },
    pageRefresh(url_key) {
      this.$emit("refreshpage", url_key);
    },
    checkedBrands(checkedBrands) {     
      this.selectedBrands = checkedBrands;      
      this.productFilterValues = this.selectedBrands.toString();
    },
    sorProducts() {
      event.preventDefault();
      this.productList = [];
      this.currentpage = 1;
    //   this.getProductsList();
    },
    intersect() {
      if (this.initial_loading_status == true) {
        this.currentpage = parseInt(this.currentpage) + 1;
        if (this.productList.length > 0) {
        //   this.getProductsList();
        }
      }
    },

    
  },
  computed: {
    ...mapGetters(["getSearchString","getCategoryList","getRecentProducts"]),
    productCnt() {
      let length = 0;
      if(this.getRecentProducts !=''){
         length = this.getRecentProducts.length;
      }
   

      return length;
    },
  },
};
</script>

<style scopped>

.ps-product .ps-product__title {
  height: 30px;
}
.out_stock {
  text-align: center;
  color: #e81111;
  font-size: 16px;
  font-weight: bold;
  padding: 6px;
}
.ps-prduct_box {
  min-height: 100px;
}

.ps-layout--shop-new{
  float: left;
  position: relative;
  width: 100%;
}

.ps-layout--shop-new .ps-layout__left{ 
    z-index: 99;
    background: #f1f1f1;
    margin-top: 25px;
   
    border: 1px solid #ddd;
    padding-bottom: 50px;
    top:139px;
   
}
.filter_left_col{
   width:25%;
   float: left;  
   width: 25%;
    float: left;
    padding-right: 20px;
    position: sticky;
    top: 116px; 
}

.desktopSidePannelall .widget_shop{margin-bottom: 0px; padding: 13px 20px 0px;}
.ps-list--categories li {
position: relative;
padding: 3px 0px 3px 10px;
font-size: 15px;
font-weight: normal;
}

.layout__right_new{
  
    float: left!important;
    width: 100%!important;
}


.subscraibeBGSec{
  float: left;
  width: 100%;
}

.ps-page--shop{
  float: left;
  width: 100%;
  padding-bottom: 50px;
}



.ps-select {
  line-height: 20px;
  padding: 10px 20px;
  border: 0;
}
@media screen and (max-width: 570px){
  .produtDeatilsDivFloat {
      width: 50%;
  }
}
@media screen and (max-width: 1200px){
    .filter_left_col{
      display: none!important;
    }
    .layout__right_new{
      width: 100%!important;
      padding-top: 34px
    }
}

</style>
